<template>
  <div class="cpt-db-header">
    <div class="hd-inner">
      <span class="title"> 海宁政务 </span>
      <img src="~@/assets/logo.svg" alt="logo" />
      <span class="title"> 航空平台</span>
    </div>
    <div class="hd-user">
      <div class="actions">
        <div class="actions-item" @click="safefly1" v-view="{sign:'hn:safeFlight'}">
          <div class="check-image"></div>
          <div class="text">飞行检查</div>
        </div>
        <div class="actions-item" @click="safefly" v-view="{sign:'hn:flightCheck'}">
          <div class="safefly-image"></div>
          <div class="text">安全飞行</div>
        </div>
        <!-- <div class="actions-item" v-if="routeMessage">
          <el-popover
            placement="bottom"
            trigger="hover"
            popper-class="action-route-popover"
            width="140"
          >
            <div class="action-route-dropdown-list">
              <template v-for="(item, index) in message_list">
                <div
                  class="action-route-dropdown"
                  :class="`${item.path == $route.path ? 'active' : ''}`"
                  
                  :key="index"
                  @click="$router.push(item.path)"
                >
                  {{ item.name }}
                </div>
              </template>
            </div>
            <div slot="reference" class="messageIcon"></div>
          </el-popover>
          <div class="text">消息通知</div>
        </div> -->
        <HeaderMessage :message_list="message_list"/>
      </div>
      <!-- 个人中心<span class="iconfont icon-gerenzhongxin"></span> -->
      <div class="personal" @click="action">
        <!-- <el-popover
            placement="bottom"
            trigger="hover"
            popper-class="action-route-popover"
            width="140"
          >
            <div class="action-route-dropdown-list">
              <div
                class="action-route-dropdown"
                :class="`${item.path == $route.path ? 'active' : ''}`"
                v-for="(item, index) in user_list"
                :key="index"
                @click="item.action ? item.action() : $router.push(item.path)"
              >
                {{ item.name }}
              </div>
            </div>
            <div slot="reference" class="imgIcon">
              <div>
                <div class="text">{{ user_info_hn.departmentName }}</div>
                <div class="text">{{ user_info_hn.name }}</div>
              </div>
              <div>
                <img style="width: 36px; height: 36px" :src="img" />
              </div>
              
            </div>
          </el-popover> -->
        <div slot="reference" class="imgIcon">
          <div style="height: 100%;">
            <div class="text" style="margin-top: 5px;">{{ user_info_hn.departmentName }}</div>
            <div class="text" style="margin-bottom: 10px;">{{ user_info_hn.name }}</div>
          </div>
          <div>
            <div class="iconfont icon-gerenzhongxin1" style="width: 36px; height: 36px;padding: 0px 10px 0px 0px;box-sizing: border-box;"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import img1 from '@/assets/home/headImg1.png'
import HeaderMessage from '@/components/dashboard/header-message'
export default {
  components:{
    HeaderMessage
  },
  computed: {
    ...mapGetters(["user_info_hn"]),
  },
  data() {
    return {
      img: img1,
      message_list: [],
      message_list1: [
        { name: "预警消息", path: "/platform/message/warning", type: 0.5 },
        { name: "需求消息", path: "/platform/message/order", type: 60 },
        { name: "审批消息", path: "/platform/message/task", type: 70 },
      ],
      routeMessage: false, // 判断是否有消息通知
    }
  },
  methods:{
    ...mapActions("user", ["Logout"]),
    safefly(){
      this.$emit('sadeflyshow',true)
    },
    safefly1(){
      this.$emit('sadeflyshow2',true)
    },
    action(){
      this.$confirm("确定要退出登录么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.Logout();
      });
    },
  },
  created() {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:message") > -1) {
      this.routeMessage = true;
    }
    if(permissionList.indexOf("hn:sky:historyWaringMessage") > -1) {
      this.message_list.push({ name: "历史预警", path: "/platform/message/warning", type: 0.5 })
    }
    if(permissionList.indexOf("hn:sky:needMessage") > -1) {
      this.message_list.push({ name: "需求消息", path: "/platform/message/order", type: 60 })
    }
    if(permissionList.indexOf("hn:sky:approvalMessage") > -1) {
      this.message_list.push({ name: "审批消息", path: "/platform/message/task", type: 70 })
    }
  },
};
</script>

<style lang="scss" scoped>
.cpt-db-header {
  height: 100px;
  background-color: #000;
  background: center url("~@/assets/dashboard/header-bg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .hd-inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      font-family: PangMenZhengDao;
      font-size: 44px;
      color: #e0e6f2;
      letter-spacing: 4px;
      font-weight: 400;
      background-image: linear-gradient(180deg, #ffffff 0%, #93CEFF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      white-space: nowrap;
    }
  }
  .hd-user {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #b5ccff;
    letter-spacing: 0;
    font-weight: lighter;
    display: flex;
    align-items: center;
    position: absolute;
    right: 60px;
    top: 2%;
    transform: translateY(-50%);
    cursor: pointer;
    // margin-right: 60px;
    .iconfont {
      width: 20px;
      font-size: 26px;
      color: #2083e7;
      margin-left: 7px;
      &:hover {
        color: #0280ff;
      }
    }
    .personal {
      text-align: center;
      padding-top: 10px;
      .text{
          font-size: 15px;
          color: #9fc4ff;
          margin-top: 5px
        }
      .imgIcon {
        width: 100px;
        height: 24px;
        width: 121px;
        height: 38px;
        padding-top: 3px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-image: url("~@/assets/home/avatarbg.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        .text{
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #DAF4FF;
          text-align: right  !important;
          margin-top: 0px;
          transform: scale(0.833);
          -webkit-transform:scale(0.833);
        }
      }
    }
    .actions {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .actions-item {
        width: 80px;
        height: 50px;
        // background-color: red;
        .safefly-image {
          width: 50px;
          height: 24px;
          background-image: url(~@/assets/platform/security.png);
          background-repeat: no-repeat;
          background-position: center;
        }
        .messageIcon{
      width: 50px;
      height: 24px;
      background-image: url(~@/assets/platform/message.png) ;
      background-repeat: no-repeat;
      background-position: center;
    }
        .check-image {
          width: 50px;
          height: 24px;
          background-image: url(~@/assets/platform/inspect.png);
          background-repeat: no-repeat;
          background-position: center;
        }
        .text {
          font-size: 10px;
          color: #9fc4ff;
          width: 76px;
          text-align: left;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.action-route-popover {
  background: rgba(12, 34, 67, 0.5);
  min-width: auto;
  padding: 10px 0;
  box-sizing: border-box;
  border: 1px solid #488cff;
  border-radius: 0;
  .popper__arrow {
    display: none;
  }
  .action-route-dropdown-list {
    .action-route-dropdown {
      text-align: center;
      font-size: 14px;
      color: #cce8ff;
      letter-spacing: 0;
      font-weight: 400;
      line-height: 30px;
      cursor: pointer;
      // &:hover,
      &.active {
        color: #2cacff;
      }
    }
  }
}
.iconfont {
        cursor: pointer;
        font-size: 18px;
        color: #9fc4ff;
        margin-right: 4px;
        &:last-child {
          margin-right: 0px;
        }
  }
</style>