<template>
  <Box title="待执行任务" width="590px" height="450px">
    <div class="cpt-task">
      <div class="total-stat">
        <div class="task-item task-item-1">
          <img
            class="task-item-icon"
            src="~@/assets/dashboard/task-1-bg.png"
            alt="task-1-bg"
          />
          <div class="task-item-props">
            <div class="prop-k">常态任务</div>
            <div class="prop-v">{{ this.countList.normalTask }}</div>
          </div>
        </div>
        <div class="task-item task-item-1">
          <img
            class="task-item-icon"
            src="~@/assets/dashboard/task-2-bg.png"
            alt="task-1-bg"
          />
          <div class="task-item-props">
            <div class="prop-k">紧急任务</div>
            <div class="prop-v">{{ this.countList.urgentTask }}</div>
          </div>
        </div>
      </div>
      <div id="task-chart" class="task-chart"></div>
    </div>
  </Box>
</template>

<script>
import { Chart } from "@antv/g2";
import Box from "../common/box";
import API from "@/api";
export default {
  components: { Box },
  data() {
    return {
      // countList: {},
      countList: {
        normalCount: 14,
        normalTask: 14,
        urgentCount: 1,
        urgentTask: 1
      },
      type: "合计",
    };
  },
  methods: {
    async init_chart() {
      // let datalist = await API.DASHBOARD.business();
      // let list = datalist.data
      // console.log(list);
      let list=   [{date: '2022-07-12', type: 'urgent', value: 0},
                  {date: '2022-07-12', type: 'normal', value: 4},
                  {date: '2022-07-10', type: 'urgent', value: 0},
                  {date: '2022-07-10', type: 'normal', value: 2},
                  {date: '2022-07-08', type: 'urgent', value: 1},
                  {date: '2022-07-08', type: 'normal', value: 4},
                  {date: '2022-07-06', type: 'urgent', value: 0},
                  {date: '2022-07-06', type: 'normal', value: 4}]
      // this.countList = list[list.length -1]
      let data = list.reduce((total, item) => {
        if (item.type) {
          total.push({
            meta: item.type,
            name: item.type == "normal" ? "常态任务" : "紧急任务",
            type: item.date + " ",
            value: item.value,
          });
        }
        return total;
      }, []);

      // data.reverse()

      // const last = list[list.length - 1];
      const last = this.countList
      data.push({
        meta: "normal",
        name: "常态任务",
        type: "合计",
        value: last.normalCount,
      });
      data.push({
        meta: "urgent",
        name: "紧急任务",
        type: "合计",
        value: last.urgentCount,
      });

      // data.sort((a, b) => a.type.charCodeAt(0) - b.type.charCodeAt(0));
      

      const chart = new Chart({
        container: "task-chart",
        autoFit: true,
      });

      chart.data(data);

      chart.scale("value", { nice: true });

      chart.legend({
        position: "top-right",
        itemName: {
          style: {
            fill: "#B9CDF8",
            fontSize: 12,
          },
        },
      });

      chart.axis("value", {
        label: null,
        line: null,
        grid: {
          line: {
            style: {
              stroke: "rgba(255,255,255,0.15)",
            },
          },
        },
      });

      chart.axis("type", {
        label: {
          style: {
            fill: "#C4D6FF",
          },
        },
      });

      chart
        .interval()
        .position("type*value")
        .color("name",['#0D7AF5D9', '#EE3A75'])
        .adjust([
          {
            type: "dodge",
            marginRatio: 0,
          },
        ]);

      chart.render();
    },
  },
  mounted() {
    this.init_chart();
  },
};
</script>

<style lang="scss" scoped>
.cpt-task {
  box-sizing: border-box;
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .total-stat {
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    .task-item {
      width: 250px;
      height: 100px;
      background: center url(~@/assets/dashboard/stat-bg.png) no-repeat;
      display: flex;
      align-items: center;
      .task-item-icon {
        width: 62px;
        height: 62px;
        margin-right: 19px;
        margin-left: 29px;
      }
      .task-item-props {
        .prop-k {
          font-size: 16px;
          color: #b5ccff;
          letter-spacing: 0;
          font-weight: 400;
        }
        .prop-v {
          font-size: 32px;
          color: #488cff;
          letter-spacing: 0;
          font-weight: 700;
        }
      }
    }
  }
  .task-chart {
    flex: 1;
  }
}
</style>