<template>
  <Box title="报告统计" width="590px" height="300px" class="cpt-report-box">
    <div class="report-list">
      <div class="report-item" v-for="item in list" :key="item.name">
        <div class="item-title">{{ item.name }}</div>
        <div class="item-value">{{ item.reportCount }}</div>
      </div>
    </div>
  </Box>
</template>

<script>
import Box from "../common/box";
import API from "@/api"
export default {
  components: { Box },
  data() {
    return {
      list: [
        {
          name:'海宁市公安',
          reportCount:'72'
        },
        {
          name:'海宁市水利局',
          reportCount:'17'
        },
        {
          name:'海宁市应急管理局',
          reportCount:'12'
        },
        {
          name:'海宁市综合行政执法局',
          reportCount:'32'
        },
        {
          name:'海宁市生态创建办',
          reportCount:'15'
        },
        {
          name:'海宁市自然资源与规划局',
          reportCount:'2'
        },
      ],
    };
  },
  methods: {
    async init_chart () {
      const list  = await API.DASHBOARD.reportTotal()
      this.list = list.data
    }
  },
  mounted() {
    // this.init_chart();
  }
};
</script>

<style lang="scss" scoped>
.cpt-report-box {
  margin-top: 16px;
  .report-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 12px;
    overflow: hidden;
    .report-item {
      width: 180px;
      height: 111px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: center url(~@/assets/dashboard/report-bg.png) no-repeat;
      margin-bottom: 10px;
      .item-title {
        font-size: 16px;
        color: #b5ccff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        margin-bottom: 12px;
      }
      .item-value {
        font-size: 32px;
        color: #488cff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 700;
      }
    }
  }
}
</style>