<template>
  <Box title="作业占比" width="590px" height="380px">
    <div id="status-chart" class="status-chart"></div>
  </Box>
</template>

<script>
import { Chart, registerShape } from "@antv/g2";
import Box from "../common/box";
import API from "@/api";

// 可以通过调整这个数值控制分割空白处的间距，0-1 之间的数值
      const sliceNumber = 0.01;

      // 自定义 other 的图形，增加两条线
      registerShape("interval", "slice-shape", {
        draw(cfg, container) {
          const points = cfg.points;
          let path = [];
          path.push(["M", points[0].x, points[0].y]);
          path.push(["L", points[1].x, points[1].y - sliceNumber]);
          path.push(["L", points[2].x, points[2].y - sliceNumber]);
          path.push(["L", points[3].x, points[3].y]);
          path.push("Z");
          path = this.parsePath(path);
          return container.addShape("path", {
            attrs: {
              fill: cfg.color,
              path,
            },
          });
        },
      });

export default {
  components: { Box },
  methods: {
    async init_chart() {
      const datalist = await API.DASHBOARD.proportion();
      const list = datalist.data
      const pending = list.pending === 0 ? 0 : (list.pending / list.count).toFixed(2);
      const inExecution = list.inExecution === 0 ? 0 : (list.inExecution / list.count).toFixed(2);
      const executionComplete =list.executionComplete === 0 ? 0 : (list.executionComplete / list.count).toFixed(2);
      const toBeDistributed =list.toBeDistributed === 0 ? 0 : (list.toBeDistributed / list.count).toFixed(2);
      const data = [
        { item: "待执行", count: list.pending, percent: Number(pending) },
        {
          item: "执行中",
          count: list.inExecution,
          percent: Number(inExecution),
        },
        {
          item: "执行完成",
          count: list.executionComplete,
          percent: Number(executionComplete),
        },
        {
          item: "待派发",
          count: list.toBeDistributed,
          percent: Number(toBeDistributed),
        },
      ];

      const chart = new Chart({
        container: "status-chart",
        width: 590,
        height: 350
      });
      chart.data(data);
      chart.legend({
        position: "top-right",
        itemName: {
          style: {
            fill: "#9EBFEE",
          },
        },
      });
      chart.scale("percent", {
        formatter: (val) => {
          val = val * 100 + "%";
          return val;
        },
      });
      chart.coordinate("theta", {
        radius: 0.85,
        innerRadius: 0.85,
      });
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
        itemTpl:
          '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
      });
      
      chart
        .interval()
        .adjust("stack")
        .position("percent")
        .shape('slice-shape')
        .color("item", (item) => {
          switch (item) {
            case "待执行":
              return "l(180) 0:rgba(17,193,178,0.2) 1:rgba(17,193,178,1)";
              break;
            case "执行中":
              return "l(180) 0:rgba(245,157,94,0.2) 1:rgba(245,157,94,1)";
              break;
            case "待派发":
              return "l(180) 0:rgba(245,94,94,0.2) 1:rgba(245,94,94,1)";
              break;
            case "执行完成":
              return "l(180) 0:rgba(11,106,216,0.2) 1:rgba(11,106,216,1)";
              break;
            default:
              break;
          }
        })

        .label("percent", (percent) => {
          return {
            style: {
              fill: "#AFCDFF",
            },
            content: (data) => {
              return `${data.item}: ${(percent * 100).toFixed(0)}%`;
            },
          };
        })
        .tooltip("item*percent*count", (item, percent, count) => {
          // percent = percent * 100 + "%";
          return {
            name: item,
            value: count,
          };
        });

      chart.interaction("element-active");

      chart.render();
    },
  },
  mounted() {
    this.init_chart();
  },
};
</script>

<style lang="scss" scoped>
.status-chart {
  height: 100%;
}
</style>