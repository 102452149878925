<template>
  <div class="cpt-db-box" :style="`width:${width}; height: ${height};`">
    <div class="box-hd">
      <!-- <div class="box-hd-border top"></div> -->
      <div class="box-hd-inner">
        <span class="iconfont icon-biaoti"></span>{{ title }}
      </div>
      <!-- <div class="box-hd-border bottom"></div> -->
    </div>
    <div class="box-bd">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "标题",
    },
    width: {
      type: String,
      default: "0px",
    },
    height: {
      type: String,
      default: "0px",
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-db-box {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: calc(100% - 12px);
    background: rgba(96, 130, 171, 0.2);
  }
  &::after {
    content: " ";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: calc(100% - 12px);
    background: rgba(96, 130, 171, 0.2);
  }
  .box-hd {
    height: 34px;
    display: flex;
    align-items: center;
    position: relative;
    flex-shrink: 0;
    .box-hd-inner {
      height: 28px;
      width: 100%;
      background: center center url(~@/assets/dashboard/box-title-bg.png) no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #edf6ff;
      letter-spacing: 0;
      font-weight: lighter;
      display: flex;
      align-items: center;
      white-space: nowrap;
      box-sizing: border-box;
      padding: 0 8px;
      transition: 2s;
      .iconfont {
        font-size: 14px;
        margin-right: 12px;
        opacity: 0.5;
        color: #ffffff;
      }
    }
    .box-hd-border {
      width: 100%;
      height: 1px;
      background: #6c8eb7;
      position: absolute;
      &.top {
        top: 0;
      }
      &.bottom {
        bottom: 0;
      }
      &::before {
        content: " ";
        display: block;
        position: absolute;
        width: 7px;
        height: 3px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #fff;
      }
      &::after {
        content: " ";
        display: block;
        position: absolute;
        width: 7px;
        height: 3px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #fff;
      }
    }
  }
  .box-bd {
    flex: 1;
    margin-top: 6px;
    /* border: 1px solid rgba(96, 130, 171, 0.2); */
    /* border-bottom-left-radius: 20px; */
    border-spacing: 50px;
    height: 100px;
    overflow: hidden;
    background: -1px -1px url("~@/assets/dashboard/border-01.png") no-repeat,
      calc(100% + 1px) -1px url("~@/assets/dashboard/border-02.png") no-repeat,
      -1px bottom url("~@/assets/dashboard/border-03.png") no-repeat,
      calc(100% + 1px) bottom url("~@/assets/dashboard/border-04.png") no-repeat;
    &::after {
      content: " ";
      display: block;
      position: absolute;
      right: 0;
      left: 50%;
      bottom: 0;
      width: calc(100% - 30px);
      transform: translateX(-50%);
      height: 1px;
      background: rgba(96, 130, 171, 0.2);
    }
  }
}
</style>