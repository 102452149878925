<template>
  <Box
    title="任务完成情况"
    width="590px"
    height="450px"
    class="cpt-message-box"
  >
    <div class="cpt-message">
      <div class="total-stat">
        <div class="task-item task-item-1">
          <img
            class="task-item-icon"
            src="~@/assets/dashboard/task-1-bg.png"
            alt="task-1-bg"
          />
          <div class="task-item-props">
            <div class="prop-k">自推报告量</div>
            <div class="prop-v">{{dataCount.every}}</div>
          </div>
        </div>
        <div class="task-item task-item-1">
          <img
            class="task-item-icon"
            src="~@/assets/dashboard/task-2-bg.png"
            alt="task-1-bg"
          />
          <div class="task-item-props">
            <div class="prop-k">接受需求量</div>
            <div class="prop-v">{{dataCount.success}}</div>
          </div>
        </div>
      </div>
      <div id="message-chart" class="message-chart"></div>
    </div>
  </Box>
</template>

<script>
import { Chart } from "@antv/g2";
import Box from "../common/box";
import API from "@/api"
export default {
  components: { Box },
  data() {
    return {
      dataCount: {
        every: null,
        success: null
      }
    }
  },
  methods: {
    async init_chart() {
      // let datalist  = await API.DASHBOARD.information()
      // let list = datalist.data
      // list = list[0]
      let list={
        every: 150,
        success: 152,
        one:[ {date: '2022-07-12', finish: 1, city: '接受需求量', temperature: 1},
              {date: '2022-07-11', finish: 5, city: '接受需求量', temperature: 5},
              {date: '2022-07-10', finish: 0, city: '接受需求量', temperature: 0},
              {date: '2022-07-09', finish: 20, city: '接受需求量', temperature: 20},
              {date: '2022-07-08', finish: 0, city: '接受需求量', temperature: 0},
              {date: '2022-07-07', finish: 0, city: '接受需求量', temperature: 0},
              {date: '2022-07-06', finish: 3, city: '接受需求量', temperature: 3}],
         two:[ {date: '2022-07-12', all: 3, city: '完成需求量', temperature: 3},
               {date: '2022-07-11', all: 6, city: '完成需求量', temperature: 6},
               {date: '2022-07-10', all: 1, city: '完成需求量', temperature: 1},
               {date: '2022-07-09', all: 1, city: '完成需求量', temperature: 1},
               {date: '2022-07-08', all: 1, city: '完成需求量', temperature: 1},
               {date: '2022-07-07', all: 4, city: '完成需求量', temperature: 4},
               {date: '2022-07-06', all: 8, city: '完成需求量', temperature: 8}]     
      }
      this.dataCount.every = list.every
      this.dataCount.success = list.success
      list.one.map(item => {item.city = '接受需求量',item.temperature = item.finish})
      list.two.map(item => {item.city = '完成需求量', item.temperature = item.all})
      let data = list.one.concat(list.two)

      const chart = new Chart({
        container: "message-chart",
        autoFit: true,
      });

      chart.data(data);
      chart.scale({
        date: {
          range: [0, 1],
        },
        temperature: {
          nice: true,
        },
      });

      chart.tooltip({
        showCrosshairs: true,
        shared: true,
      });

      chart.axis("temperature", {
        label: {
          style: {
            fill: "#AFCDFF",
          },
        },
        line: {
          style: {
            stroke: "rgba(255,255,255,0.45)",
          },
        },
        grid: {
          line: {
            style: {
              stroke: "rgba(255,255,255,0.15)",
            },
          },
        },
      });

      chart.axis("date", {
        line: {
          style: {
            stroke: "rgba(255,255,255,0.15)",
          },
        },
        grid: null,
        label: {
          style: {
            fill: "#AFCDFF",
          },
        },
      });

      chart.legend(false)

      chart.line().position("date*temperature").color("city").shape("smooth");

      chart.point().position("date*temperature").color("city").shape("circle");

      chart.render();
    },
  },
  mounted() {
    this.init_chart();
  },
};
</script>

<style lang="scss" scoped>
.cpt-message-box {
  margin-top: 16px;
  .cpt-message {
    box-sizing: border-box;
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .total-stat {
      box-sizing: border-box;
      padding: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-shrink: 0;
      .task-item {
        width: 250px;
        height: 100px;
        background: center url(~@/assets/dashboard/stat-bg.png) no-repeat;
        display: flex;
        align-items: center;
        .task-item-icon {
          width: 62px;
          height: 62px;
          margin-right: 19px;
          margin-left: 29px;
        }
        .task-item-props {
          .prop-k {
            font-size: 16px;
            color: #b5ccff;
            letter-spacing: 0;
            font-weight: 400;
          }
          .prop-v {
            font-size: 32px;
            color: #488cff;
            letter-spacing: 0;
            font-weight: 700;
          }
        }
      }
    }
    .message-chart {
      flex: 1;
    }
  }
}
</style>