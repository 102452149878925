<template>
  <div class="cpt-dashboard">
    <Header  @sadeflyshow ='sadeflyshow' @sadeflyshow2 = 'sadefly2show' />
    <div class="cpt-db-bd">
      <div class="left">
        <EarthBox />
        <OrganizeBox />
      </div>
      <div class="center">
        <TaskBox />
        <MessageBox />
      </div>
      <div class="right">
        <StatusBox />
        <ReportBox />
        <PathBox />
      </div>
    <SafeFly 
      :securityFlyVisible="FlyVisible"
      :clear="() => FlyVisible = false"
    />
    <!-- <CheckSafe    飞行清单检查去除 11.18
      :checkVisibles="checkVisible"
      :clear="() => checkVisible = false"
      
    ></CheckSafe> -->
    </div>
  </div>
</template>

<script>
import Header from "@/components/dashboard/header";
import EarthBox from "@/components/dashboard/earth-box";
import OrganizeBox from "@/components/dashboard/organize-box";
import TaskBox from "@/components/dashboard/task-box";
import MessageBox from "@/components/dashboard/message-box";
import StatusBox from "@/components/dashboard/status-box";
import ReportBox from "@/components/dashboard/report-box";
import PathBox from "@/components/dashboard/path-box";
// import CheckSafe from '@/components/header-right/check_safe.vue';
import SafeFly from "@/components/header-right/safeFly.vue";

export default {
  data(){
    return{
      FlyVisible: false,
      checkVisible:false,
    }
  },
  components: {
    Header,
    EarthBox,
    OrganizeBox,
    TaskBox,
    MessageBox,
    StatusBox,
    ReportBox,
    PathBox,
    SafeFly,
  },
    methods: {
    sadeflyshow(data){
      this.FlyVisible = data
    },
    sadefly2show(data){
      this.checkVisible = data
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-dashboard {
  background: center url("~@/assets/dashboard/bg-border.png") no-repeat,
    center url("~@/assets/dashboard/bg.jpg") no-repeat;
  background-color: #000;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  padding-bottom: 40px;
  .cpt-db-bd {
    flex: 1;
    padding: 21px 53px 40px 53px;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    justify-content: space-between;
  }
}
</style>