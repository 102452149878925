<template>
  <Box title="门户入口" width="590px" height="200px" class="cpt-report-box">
    <div class="list-wrap">
      <div class="item-box" @click="go(item)" v-for="item in list" :key="item.name">
        <span class="item-title">{{ item.name }}</span>
      </div>
    </div>
  </Box>
</template>

<script>
import Box from "../common/box";
export default {
  components: { Box },
  data() {
    return {
      list:[],
      list1: [
        { id: 3, name: "数据方舟", path: "/" },
        {
          id: 2,
          name: "天空卫士",
          path: "/platform/order/task",
        },
        {
          id: 3,
          name: "驾驶舱",
          path: "/platform/order/visual/view/device",
        },
      ],
    };
  },
  created () {
    // const permissions = JSON.parse(sessionStorage.getItem("permissions"));
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:sky") > -1) {
      this.list.push(this.list1[0])
    } 
    if (permissionList.indexOf("hn:driver") > -1) {
      this.list.push(this.list1[1])
    } 
    if (permissionList.indexOf("hn:lead") > -1) {
      this.list.push(this.list1[2])
    }
  },
  methods: {
    go(data) {
      this.$router.push(data.path);
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-report-box {
  margin-top: 16px;
  .list-wrap {
    height: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 8px 40px 8px 40px;
    overflow: auto;
    position: relative;
    .item-box {
      width: 121px;
      height: 142px;
      background: center center url(~@/assets/home/gateway-bg.png) no-repeat;
      background-size: 100% 100%;
      position: relative;
      cursor: pointer;
      &:hover {
        .item-title {
          color: #fff;
        }
      }
      .item-title {
        width: 100%;
        font-size: 16px;
        color: #abc5ff;
        text-align: center;
        font-weight: 400;
        position: absolute;
        left: 50%;
        bottom: 22%;
        transform: translateX(-50%);
        white-space: normal;
        transition: 0.3s;
      }
    }
  }
}
</style>