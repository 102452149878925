<template>
  <div class="cpt-stat">
    <div class="stat-inner">
      <div class="list">
        <div class="list-item" v-for="item in list" :key="item.id">
          <div class="title-box">
            <span class="title">{{ item.title }}</span>
            <span class="unit" :style="`background:${item.color};`">{{
              item.unit
            }}</span>
          </div>
          <div class="value"><span>{{ item.value }}</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api"
export default {
  data() {
    return {
      list: [],
    };
  },
  methods: {
    async init_chart() {
      // const datacount  = await API.DASHBOARD.countDate()
      // const countList = datacount.data
      this.list = [
        {
          id: 1,
          title: "年业务量",
          unit: "年",
          // value: countList.year,
          value: 150,
          color: "#3154F0",
        },
        {
          id: 2,
          title: "月业务量",
          unit: "月",
          // value: countList.moon,
          value: 30,
          color: "#7B40F7",
        },
        {
          id: 3,
          title: "周业务量",
          unit: "周",
          // value: countList.week,
          value: 7,
          color: "#1B9C91",
        },
        {
          id: 4,
          title: "日业务量",
          unit: "日",
          // value: countList.day,
          value: 1,
          color: "#EF985A",
        },
      ]
    }
  }, 
  mounted() {
    this.init_chart();
  }
};
</script>

<style lang="scss" scoped>
.cpt-stat {
  height: 108px;
  display: flex;
  background: -1px bottom url("~@/assets/dashboard/border-03.png") no-repeat,
    calc(100% + 1px) bottom url("~@/assets/dashboard/border-04.png") no-repeat;
  padding: 10px;
  box-sizing: border-box;
  .stat-inner {
    box-sizing: border-box;
    background: #102e58;
    display: flex;
    align-items: center;
    width: 100%;
    .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 12px;
      &-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title-box {
          display: flex;
          align-items: center;
          justify-content: left;
          .title {
            font-size: 16px;
            color: #b5ccff;
            letter-spacing: 0;
            font-weight: lighter;
            text-align: left;
          }
          .unit {
            // display: inline-block;
            font-size: 12px;
            color: #ffffff;
            letter-spacing: 0;
            font-weight: lighter;
            width: 28px;
            height: 16px;
            border-radius: 2px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 6px;
          }
        }
        .value {
          font-size: 32px;
          color: #488cff;
          letter-spacing: 0;
          font-weight: 700;
          text-align: left;
        }
      }
    }
  }
}
</style>