<template>
  <div>
    <Box title="潮鹰数据大屏业务界面" width="550px" height="480px">
      <div class="earth-container">
        <div class="earth-el" id="earth-el"></div>
      </div>
    </Box>
    <Stat />
  </div>
</template>

<script>
import Box from "../common/box";
import Stat from "./stat";
import earth from "../../../assets/echart/earth.jpg";
import bathymetry from "../../../assets/echart/bathymetry.jpg";
import night from "../../../assets/echart/night.jpg";
import clouds from "../../../assets/echart/clouds.png";

import * as echarts from "echarts";
import "echarts-gl";

import FlightsData from "./flights.json";

export default {
  components: { Box, Stat },
  methods: {
    init_earth() {
      const data = FlightsData;
      function getAirportCoord(idx) {
        return [data.airports[idx][3], data.airports[idx][4]];
      }
      var routes = data.routes.map(function (airline) {
        return [getAirportCoord(airline[1]), getAirportCoord(airline[2])];
      });

      // const ROOT_PATH =
      //   "https://cdn.jsdelivr.net/gh/apache/echarts-website@asf-site/examples";
      const my_chart = echarts.init(document.getElementById("earth-el"));
      const option = {
        backgroundColor: "",
        globe: {
          baseTexture: earth,
          heightTexture: bathymetry,
          displacementScale: 0.1,
          shading: "lambert",
          light: {
            ambient: {
              intensity: 0.5,
            },
            main: {
              intensity: 0.5,
            },
          },
          layers: [
            {
              type: "blend",
              blendTo: "emission",
              texture: night,
            },
            {
              type: "overlay",
              texture: clouds,
              shading: "lambert",
              distance: 5,
            },
          ],
        },
        series: {
          type: "lines3D",
          coordinateSystem: "globe",
          blendMode: "lighter",
          lineStyle: {
            width: 1,
            color: "rgb(50, 50, 150)",
            opacity: 0.1,
          },
          effect: {
            show: true,
            trailWidth: 1,
            trailOpacity: 1,
            trailLength: 0.2,
            constantSpeed: 5,
          },
          data: routes,
        },
      };
      my_chart.setOption(option);
    },
  },
  mounted() {
    this.init_earth();
  },
};
</script>

<style lang="scss" scoped>
.earth-container {
  height: 100%;
  box-sizing: border-box;
  /* padding: 20px; */
  .earth-el {
    height: 100%;
    /* border: 1px solid red; */
  }
}
</style>
