<template>
  <Box title="近期任务" width="550px" height="312px" class="cpt-organize-box">
    <div class="list">
      <div class="list-hd">
        <div class="list-hd-title title-name">局办单位</div>
        <div class="list-hd-title">前一天</div>
        <div class="list-hd-title">今天</div>
        <div class="list-hd-title">后一天</div>
      </div>
      <div class="list-bd">
        <div class="list-bd-item" v-for="item in list" :key="item.name">
          <div class="list-bd-item-name">{{ item.name }}</div>
          <div class="list-bd-item-before">{{ item.yesterday }}</div>
          <div class="list-bd-item-now">{{ item.today }}</div>
          <div class="list-bd-item-after">{{ item.tomorrow }}</div>
        </div>
      </div>
    </div>
  </Box>
</template>

<script>
import Box from "../common/box";
import API from "@/api"
export default {
  components: { Box },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    async init_chart() {
      // const data = await API.DASHBOARD.taskTotal()
      // this.list = data.data
      this.list=[
        {
          name: "海宁市公安",
          today: 1,
          tomorrow: 2,
          yesterday: 1
        },{
          name: "海宁市水利局",
          today: 0,
          tomorrow: 1,
          yesterday: 0
        },{
          name: "海宁市应急管理局",
          today: 0,
          tomorrow: 0,
          yesterday: 1
        },{
          name: "海宁市综合行政执法局",
          today: 1,
          tomorrow: 0,
          yesterday: 1
        },{
          name: "海宁市生态创建办",
          today: 0,
          tomorrow: 1,
          yesterday: 0
        },{
          name: "海宁市自然资源与规划局",
          today: 0,
          tomorrow: 0,
          yesterday: 0
        },
      ]
    }
  },
  mounted() {
    this.init_chart()
  }
};
</script>

<style lang="scss" scoped>
.cpt-organize-box {
  margin-top: 16px;
  .list {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px 8px;
    &-hd {
      flex-shrink: 0;
      height: 36px;
      background: #102e58;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 20px;
      &-title {
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 500;
      }
      
    }
    &-bd {
      flex: 1;
      overflow: auto;
      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 0 20px;
        height: 36px;
        &:nth-of-type(even) {
          background: rgba(25, 52, 89, 0.5);
        }
        &-name,
        &-before,
        &-now,
        &-after {
          font-size: 16px;
          color: #c4d6ff;
          letter-spacing: 0;
          text-align: left;
          font-weight: 400;
        }
        &-name{
          min-width: 200px;
        };
      }
    }
  }
  .title-name {
    min-width: 230px;
  } 
}
</style>